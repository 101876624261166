import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  updateCompanyName,
  updateTax,
  updateAddress,
  updateCountryOptions,
  updateLinerOptions,
} from "../registraionSlice";
import axios, { AxiosError } from "axios";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axiosInstance, {
  axiosCache,
  axiosCacheInstance,
} from "../../../../axiosSetup";
import { updateDashbaordRefresh } from "../../../common/components/DataTable/slices/DataTable";
import { a } from "msw/lib/glossary-de6278a9";

const csrfMeta = document.querySelector<HTMLMetaElement>("meta[name='_csrf']");
// import axios from '../../../../axiosSetup';

/* export interface RegistrationData {
  company_name: string;
  address_details:string;
  state: string;
  country_code: string;
  request_id:string;
  source:string;
  company_number:string
} */
/*  export interface RegistrationData {
 country: string;
  state: string;
  company_name:string

  reg_number: string;
  current_status:string;
  address:string;
} */

export interface RegistrationData {
  country: string;
  state: string;
  company_name: string;

  reg_number: string;
  current_status: string;
  address: string;
  full_address: string;
}
export const addRegistrationDetails = createAsyncThunk(
  "registration/addEin",
  async (state: any) => {
    const response = await axios({
      url: `https://places.geo.us-east-1.amazonaws.com/places/v0/indexes/explore.place.Esri/search/suggestions`,
      method: "POST",

      headers: {
        AccessKey: "AKIARSXGUNDQFA7LKMV6",
        SecretKey: "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",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
      },
    });
    return {
      // next: null,
      // previous: null,
      list: response.data,
      // list: data,
    };
  }
);
export const addRegistrationDetailsCompany = createAsyncThunk(
  "registrationCompany/addCompanyRegistration",
  async (state: any) => {
    const response = await axios({
      url: `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/users/kyb/company/search`,
      method: "POST",

      data: state,
    });

    return {
      // next: null,
      // previous: null,
      list: response.data.data, // list: data,
    };
  }
);

export const fetchAddressSuggestions: any = createAsyncThunk(
  "registrationCompany/fetchAddressSuggestions",
  async (payload: any) => {
    try {
      if (payload?.text) {
        if (payload?.country !== null) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/places/v0/indexes/explore.place.Esri/search/suggestions?text=${payload?.text}&filterCountries=${payload?.country}`
          );
          return response.data;
        } else {
          const response = await axios.get(
            `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/places/v0/indexes/explore.place.Esri/search/suggestions?text=${payload?.text}`
          );
          return response.data;
        }
      }
      return [];
    } catch (error) {
      console.error(error);

      return [];
    }
  }
);

export const fetchCompanyDetailsForVerification = createAsyncThunk(
  "registrationCompany/companyNameVerification",
  async (state: any, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/users/kyb/customer/details`,
        {
          // ...state
          // method: 'POST',
          companyNumber: state.company_number,
          countryCode: state.country_code,
        }
      );
      dispatch(updateTax(response.data));

      dispatch(updateCompanyName(response.data.data[0].company_name));
      dispatch(updateAddress(response.data.data[0].full_address));
      return response.data;
    } catch (error) {
      console.error(error);

      return {};
    }
  }
);

export const getCountryList = createAsyncThunk(
  "registrationCompany/getCountryList",
  async (state: any, { dispatch }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/country/getCountryDetailsByOdexActive?odexActive=Y`
      );
      const options = response.data.map((item: any) => ({
        value: item.countryShortCode,
        text: item.countryName,
        full: item.countryIsoCode,
      }));
      options.sort((a: any, b: any) => (a.text > b.text ? 1 : -1));
      dispatch(updateCountryOptions(options));
      return options;
    } catch (error) {
      console.error(error);

      return {};
    }
  }
);
export const trackRegistrationRequest = createAsyncThunk(
  "registrationCompany/trackRequest",
  async (state: any, { dispatch }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/customer/trackRequest?id=${state}`
      );
      return response;
    } catch (error) {
      console.error(error);

      return {};
    }
  }
);
export const getIntemnityLetterPdf = createAsyncThunk(
  "registrationCompany/getIntemnityLetterPdf",
  async (state: any, { dispatch }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/customer/getIntemnityLetterPdf`,
        {
          companyTin: state.companyTin,
          companyName: state.companyName,
          directorName: state.directorName,
          directorEmail: state.directorEmail,
          directorMobile: state.directorMobile,
          adminMobile: state.adminMobile,
          adminEmail: state.adminEmail,
          adminName: state.adminName,
          isAccepted: state.isAccepted,
        },

        {
          responseType: "blob", // Ensure the response is handled as a Blob
        }
      );

      if (response.status === 200 && response.data instanceof Blob) {
        return response.data; // Return Blob if response is a valid PDF
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      console.error(error);

      return {};
    }
  }
);
export const taxIDValidation = createAsyncThunk(
  "registrationCompany/taxIDValidation",
  async (state: any, { dispatch }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/customer/checkCustomerTinExist?
taxid=${state}`
      );
      return response.data;
    } catch (error) {
      console.error(error);

      return {};
    }
  }
);
export const fetchLinerNameByCountry = createAsyncThunk(
  "registration/fetchLinerName",
  async (payload: any, { dispatch }) => {
    try {
      const response = await axios.get(
        `/api/edo-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguageWithoutSession?language=EN&codeType=ACTIVE_LINERS&countryCode=${payload.country_code}`
      );
      const options = response.data?.map((item: any) => ({
        value: item.codeId,
        text: item.codeDesc,
      }));
      if (options?.length == 0)
        console.warn(
          `No Liner options found for country ${payload.country_code}`
        );
      dispatch(
        updateLinerOptions(
          options?.length == 0
            ? [
              {
                value: 1,
                text: "Hapag Lloyd",
              },
            ]
            : options
        )
      );
      if (options?.length == 0)
        console.warn(
          `No Liner options found for country ${payload.country_code}`
        );
      dispatch(
        updateLinerOptions(
          options?.length == 0
            ? [
              {
                value: 1,
                text: "Hapag Lloyd",
              },
            ]
            : options
        )
      );
      return options;
    } catch (error) {
      console.error(error);
      return {};
    }
  }
);

export const registerCustomerAndUser = createAsyncThunk(
  "registration/add",
  async (payload: any, { getState }: any) => {
    const state: RootState = getState();

    try {
      let partnerData: any = [];
      partnerData = state.registration.partnerTable
        .filter((i) => i?.customerCode !== "" && i?.customerCode !== null)
        .map((i) => ({
          linerId: i.liner,
          mapCode: i.customerCode,
          fileName: i.attachmentName,
          serverFilePath: i.serverPath,
          tempFileName: i.serverName,
        }));

      // upload validation
      if (
        (state.registration.taxDocument.loading > 0 &&
          state.registration.taxDocument.loading != 100) ||
        state.registration.documentTable.findIndex(
          (i) => i.loading > 0 && i.loading != 100
        ) !== -1 ||
        state.registration.partnerTable.findIndex(
          (i) => i.loading > 0 && i.loading != 100
        ) !== -1
      ) {
        toast.error("pending file upload");
        return;
      }
      // error checking
      if (
        state.registration.taxDocument.error ||
        state.registration.documentTable.findIndex((i) =>
          Object.values(i.error).includes(true)
        ) !== -1 ||
        state.registration.partnerTable.findIndex((i) =>
          Object.values(i.error).includes(true)
        ) !== -1
      ) {
        return;
      }
      const response = await axios({
        method: "post",
        url: `/api/user-management/v1/customer/registerCustomerAndUser`,
        headers: {
          "X-CSRF-TOKEN":
            csrfMeta && csrfMeta?.content !== null ? csrfMeta.content : "",
        },
        data: {
          countryCode: payload?.country
            ? payload?.country.toString().substring(0, 2)
            : "",
          customerTaxId: payload?.taxID,
          customerName: payload?.companyName,
          customerAddress: payload?.address,
          userName: payload?.userName,
          loiName: payload?.indemnityLetter,
          email: payload?.email ? payload?.email.toLowerCase() : "",
          contactNo: payload?.phoneNumber,
          language: "EN_US",
          customerMapping:
            payload?.country === "TZ"
              ? [
                {
                  linerId: Number(payload.linerIdForCustomerMapping),
                  mapCode: "",
                },
              ]
              : partnerData,

          isTNCAccepted:
            payload?.acceptTerms !== undefined &&
              payload?.acceptTerms !== null &&
              payload?.acceptTerms === true
              ? "Y"
              : "N",
          taxFileName: state.registration.taxDocument.name,
          taxServerFilePath: state.registration.taxDocument.serverPath,
          taxTempFileName: state.registration.taxDocument.serverName,
          companyIdList: payload.companyType,
          customerDocumentInputs: state.registration.documentTable.map(
            (item) => ({
              docId: item.docId,
              docNo: item.number,
              issueDate: item.issueDate,
              validTillDate: item.validityDate,
              fileName: item.attachmentName,
              serverFilePath: item.serverPath,
              tempFileName: item.serverName,
              //  isApproved: item.verified,
            })
          ),
          terminals:
            payload.country == "TZ"
              ? payload?.portOperator?.map((operator: any) => ({
                terminalId: Number(operator),
              }))
              : [],

          shippingLines:
            payload.country == "TZ"
              ? payload?.shippingLines?.map((id: any) => ({
                shippingLineId: Number(id), // Convert string to number if needed
              }))
              : [],
          loiServerFilePath: state.registration.indemnityDocument.serverPath,
          loiTempFileName: state.registration.indemnityDocument.serverName,
          loiFileName: state.registration.indemnityDocument.name,
          loiJson: payload.country == "TZ" ? JSON.parse(payload.loiJson) : {},
          port:
            payload.country == "TZ"
              ? {
                portCode: Number(payload.seaPort),
              }
              : {},
          type: payload.type,

          faxNo: payload.faxNumber,
        },
      });

      // const response = await axios.post(
      //   `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/customer/registerCustomerAndUser`,
      //   {
      //     countryCode: payload?.country.toString().substring(0, 2),
      //     customerTaxId: payload?.taxID,
      //     customerName: payload?.companyName,
      //     customerAddress: payload?.address,
      //     userName: payload?.userName,
      //     email: payload?.email,
      //     contactNo: payload?.phoneNumber,
      //     language: 'EN_US',
      //     customerMapping: data,
      //   },

      //   { 'X-CSRF-TOKEN': csrfMeta?.content !== null ? csrfMeta.content : '' }
      // );
      return response;
    } catch (error) {
      console.error(error);
      return {};
    }
  }
);
export const getRegistrationDetails = createAsyncThunk(
  "registraion/getRegistrationDetails",
  async (decrptrequestId: any) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_GATEWAY_HOST
        }/api/user-management/v1/customer/getRegistrationDetails?requestId=${Object.values(decrptrequestId)[0]
        }`
      );
      return response;
      // return { list: response.data };
    } catch (error) {
      return {};
    }
  }
);

export const approveRejectRegistration = createAsyncThunk(
  "instruction/approveRejectReturn",
  async (payload: any, { getState }: any) => {
    const state: RootState = getState();
    try {
      // const response = await axios({
      //   url: `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/customer/approveRejectRegistrationDetails?requestType=${payload.requestType}`,
      //   method: 'POST',
      //   data: {
      //     payload
      //   },
      // });

      const allVerified = payload.documentOptions
        .filter((docOption: any) => docOption.m.isVerificationReq === true) // Only select documents that require verification
        .every((docOption: any) => {
          // Find the document in the documentTable by matching docId
          const matchingDoc = state.registration.documentTable.find(
            (doc) => doc.docId === docOption.value
          );

          // Check if the document's `verified` field is not 'Y' (i.e., it's not verified)
          const isNotVerified = matchingDoc?.verified !== "Y"; // `verified` should be 'Y' for success, anything else means failure

          // If a required document is not verified, return false (meaning verification is incomplete)
          return !isNotVerified; // If it's not verified (verified !== "Y"), return false
        });

      if (
        !payload.isUserGroupSelected &&
        payload.Payloadnew.countryCode !== "TZ" &&
        payload.Payloadnew.requestType == 1
      ) {
        toast.dark("User Groups are mandatory");
        return;
      }
      if (
        !payload.isUserGroupSelectedTZ &&
        payload.Payloadnew.countryCode == "TZ" &&
        payload.Payloadnew.requestType == 1
      ) {
        toast.dark("User Groups are mandatory");
        return;
      }
      if (
        payload.taxDocument?.isVerificationReq == false &&
        payload.Payloadnew.countryCode == "TZ" &&
        payload.documentOptions.verifyTax !== "Y" &&
        payload.Payloadnew.requestType == 1 &&
        payload.verifyTax !== "Y"
      ) {
        toast.error("Verification of Tax Document is required");
        return "";
      }

      if (
        payload.indemnityDocument?.isVerificationReq == false &&
        payload.Payloadnew.countryCode == "TZ" &&
        payload.documentOptions.verifyIndeminity !== "Y" &&
        payload.Payloadnew.requestType == 1 &&
        payload.verifyIndeminity !== "Y"
      ) {
        toast.error("Verification of Indemnity Document is required");
        return "";
      }

      if (
        !allVerified &&
        payload.Payloadnew.countryCode == "TZ" &&
        payload.Payloadnew.requestType == 1
      ) {
        toast.error(
          "Verification required for all documents before submission."
        );
        return "";
      }

      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/user-management/v1/customer/approveRejectRegistrationDetails?requestType=${payload.Payloadnew.requestType}`,
        {
          ...payload.Payloadnew,

          customerDocumentInputs: state.registration.documentTable.map(
            (item) => ({
              docId: item.docId,
              docNo: item.number,
              issueDate: item.issueDate,
              validTillDate: item.validityDate,
              fileName: item.attachmentName,
              serverFilePath: item.serverPath,
              tempFileName: item.serverName,
              isApproved: item.verified,
              partnerDocumentId: item.regPartnerDocumentId,
            })
          ),
          terminals: payload.Payloadnew.terminals,
          shippingLines: payload.Payloadnew.shippingLines,

          taxFileName: state.registration.taxDocument.name,
          taxServerFilePath: state.registration.taxDocument.serverPath,
          taxTempFileName: state.registration.taxDocument.serverName,
          loiServerFilePath: state.registration.indemnityDocument.serverPath,
          loiTempFileName: state.registration.indemnityDocument.serverName,
          loiFileName: state.registration.indemnityDocument.name,
          loiJson: JSON.parse(payload.Payloadnew.loiJson),
          /*    payload.Payloadnew.countryCode == "TZ"
                ? payload.Payloadnew.loiJsonString
                : {}, */
          port: payload.Payloadnew.port,

          type: payload.Payloadnew.type,
          partnerId: payload.Payloadnew.partnerId,

          isloiapproved: payload.verifyIndeminity,
          isIdentityNoApproved: payload.verifyTax,
        }
      );

      if (response.data.responseMsg) {
        toast.success(response.data.responseMsg, {
          toastId: "2330008",
        });
        return response.data;
      }

      /*      setTimeout(() => {
        window.location.href = '/registrationmaster';
      }, 1000); */
      // return response.data;
    } catch (e) {
      const error = e as AxiosError;
      console.error(error);
      toast.dark("Error");

      // return {};
    }
  }
);

export const getCompanyTypes = createAsyncThunk(
  "registration/getCompanyTypes",
  async (payload: any) => {
    try {
      const response = await axios.get(
        `/api/edo-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguageWithoutSession?language=EN&codeType=COMPANY_TYPE&linerId=1&countryCode=${payload.partnerCountry}`
      );

      const options = response?.data?.map((item: any) => ({
        value: item.codeId,
        text: item.codeDesc,
      }));

      return options;
    } catch (e) {
      const error = e as AxiosError;
      return null;
    }
  }
);

export const getRegistrationDocuments = createAsyncThunk(
  "registration/getRegistrationDocuments",

  async (payload: { country: string; companyType: Array<string> }) => {
    if (payload?.companyType?.length < 1) return [];
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/edo-rest-v1/v1/common/attachmentConfig?countryCode=${payload.country}&instructionTy=300`,
        payload?.country == "TZ" ? [payload.companyType] : payload.companyType
      );
      if (response?.data.message) throw Error(response?.data.message);
      return response?.data;
    } catch (e) {
      return [];
    }
  }
);

export const getRegistrationTaxDocConfig = createAsyncThunk(
  "registration/getRegistrationTaxDocConfig",

  async (payload: { country: string }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/edo-rest-v1/v1/common/attachmentConfig?countryCode=${payload.country}&instructionTy=302`
      );
      if (response.data?.message) throw Error(response.data.message);
      return response.data?.[0]?.isMandatory ?? "Y";
    } catch (e) {
      return [];
    }
  }
);
export const getRegistrationTaxDocVerify = createAsyncThunk(
  "registration/getRegistrationTaxDocVerify",

  async (payload: { country: string }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/edo-rest-v1/v1/common/attachmentConfig?countryCode=${payload.country}&instructionTy=302`
      );
      if (response?.data.message) throw Error(response.data.message);

      /*  response.data = [
        {
          attachmentConfigId: 68,
          docId: 1,
          isMandatory: "Y",
          docName: "TAX_ID_ATT",
          companyType: 2,
          isVerificationReq: "Y",
        },
      ]; */

      return response.data?.[0]?.isVerificationReq ?? "Y";
    } catch (e) {
      return [];
    }
  }
);
export const getRegistrationPartnerDocConfig = createAsyncThunk(
  "registration/getRegistrationPartnerDocConfig",

  async (payload: { country: string; liner: any }) => {
    try {
      const response = await axiosCache.post(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/edo-rest-v1/v1/common/attachmentConfig?countryCode=${payload.country}&instructionTy=301&linerId=${payload.liner}`,
        undefined,
        {
          cache: {
            ttl: 10 * 60 * 1000,
          },
        }
      );
      if (response.data.message) throw Error(response.data.message);

      return response.data?.[0]?.isMandatory ?? "Y";
    } catch (e) {
      return [];
    }
  }
);
export const getSeaPortTypes = createAsyncThunk(
  "registration/getSeaPortTypes",
  async (payload: any) => {
    try {
      /*    const response = await axios.get(
        `/api/edo-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguageWithoutSession?language=EN&codeType=SEAPORT_TYPE&linerId=1&countryCode=${payload.partnerCountry}`
      ); */
      const response = await axios.post(
        `/api/user-management/v1/customer/getPortList?countryCode=${payload.partnerCountry}`
      );
      /*  const options = response?.data?.map((item: any) => ({
        value: item?.codeId,
        text: item?.codeDesc,
      })); */
      const options = Object.entries(response?.data).map(([key, value]) => ({
        value: key,
        text: value,
      }));

      return options;
    } catch (e) {
      const error = e as AxiosError;
      return null;
    }
  }
);
export const getPortOperatorTypes = createAsyncThunk(
  "registration/getPortOperatorTypes",
  async (payload: any) => {
    try {
      /*     const response = await axios.get(
        `/api/edo-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguageWithoutSession?language=EN&codeType=PORT_OPERATOR_TYPE&linerId=1&countryCode=${payload.partnerCountry}`
      ); */
      const response = await axios.post(
        `/api/user-management/v1/customer/getTerminalList?countryCode=${payload.partnerCountry}`
      );

      /*  const options = response?.data?.map((item: any) => ({
        value: item?.codeId,
        text: item?.codeDesc,
      })); */
      const options = Object.entries(response?.data).map(([key, value]) => ({
        value: key,
        text: value,
      }));

      return options;
    } catch (e) {
      const error = e as AxiosError;
      return null;
    }
  }
);
export const getShippingLineTypes = createAsyncThunk(
  "registration/getShippingLineTypes",
  async (payload: any) => {
    try {
      /*  const response = await axios.get(
        `/api/edo-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguageWithoutSession?language=EN&codeType=SHIPPING_LINE_TYPE&linerId=1&countryCode=${payload.partnerCountry}`
      );
 */
      const response = await axios.post(
        `/api/user-management/v1/customer/getShippingLineList?countryCode=${payload.partnerCountry}`
      );
      /*      const options = response.data.map((item: any) => ({
        value: item.codeId,
        text: item.codeDesc,
      })); */
      const options = Object.entries(response?.data).map(([key, value]) => ({
        value: key,
        text: value,
      }));

      return options;
    } catch (e) {
      const error = e as AxiosError;
      return null;
    }
  }
);

export const getRegistrationIndemnityDocuments = createAsyncThunk(
  "registration/getRegistrationIndemnityDocuments",

  async (payload: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_GATEWAY_HOST}/api/edo-rest-v1/v1/common/attachmentConfig?countryCode=${payload}&instructionTy=301`
      );
      if (response.data.message) throw Error(response.data.message);
      return response.data;
      /*   return [
        {
          name: "",
          serverPath: null,
          serverName: null,
          loading: 1,
          m: false,
          error: false,
          isVerificationReq: "N",
        },
      ]; */
    } catch (e) {
      return [];
    }
  }
);
