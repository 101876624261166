/**
 * Editable partner details table to be shown on registration
 */

import { Button, Form } from "react-bootstrap";
import DataTable from "../../common/components/DataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useDispatch } from "react-redux";
import { useEffect, useMemo } from "react";
import { fetchLinerNameByCountry, getRegistrationPartnerDocConfig } from "./actions/Registration";
import { addPartnerTableRow, removePartnerTableRow, setPartnerTableCols, setPartnerTableRow } from "./registraionSlice";
import UploadThing from "../../../components/common/UploadThing";
import InfoDiv from "../../../components/common/InfoDiv";


export default function RegistrationPartnerTable({ country, isDisabled, disableAttach, requestId }: any,) {

    const { t } = useTranslation()
    const data = useSelector((state: RootState) => state.registration.partnerTable)
    const dispatch = useDispatch<AppDispatch>()
    const linerId = useSelector((state: RootState) => state.navbarTop.linerId)
    const linerOptions = useSelector(
        (state: RootState) => state.registration.linerOptions
    );
    const codeManualLiners = useMemo(() => [1, '1'], [])

    useEffect(() => {
        if (country) dispatch(fetchLinerNameByCountry({ country_code: country })).unwrap().then((d) => {
            if (!isDisabled && !disableAttach) {
                const firstOption = d.length > 0 ? d[0].value : 1
                dispatch(getRegistrationPartnerDocConfig({ country: country, liner: firstOption }))
                    .unwrap().then((data) => {
                        dispatch(setPartnerTableCols(
                            {
                                liner: firstOption,
                                m: data == 'Y'
                            }
                        ))
                    })
            }

        })
    }, [country])

    const tableColumnConfig = [
        {
            accessor: 'rowNumber',
            Header: 'rowNumber',
        },
        {
            accessor: 'liner',
            Header: t('resource_1:liner'),
            width: 200,
            minWidth: 200,
            maxWidth: 220,
            Cell: (rowData: any) => {
                const { rowNumber, liner

                } = rowData.row.original;

                return (
                    <Form.Select
                        value={liner || ''}
                        className={`fs--1 form-control  form-select`}
                        disabled={(linerOptions?.length ?? 0) < 2 || isDisabled}
                        onChange={(e) => {
                            const value = e.target.value
                            if (!isDisabled && !disableAttach) {
                                dispatch(getRegistrationPartnerDocConfig({ country: country, liner: e.target.value }))
                                    .unwrap().then((data) => {
                                        dispatch(setPartnerTableRow(
                                            {
                                                rowNumber,
                                                liner: value,
                                                m: data == 'Y'
                                            }
                                        ))
                                    })
                            }
                            else {
                                dispatch(setPartnerTableRow(
                                    {
                                        rowNumber,
                                        liner: e.target.value,
                                    }
                                ))
                            }

                        }}
                    >
                        {linerOptions.map((i: any) => <option value={`${i.value}`}>{i.text}</option>)}
                        {linerOptions.length == 0 && <option>no liners</option>}

                    </Form.Select>
                );
            },
        },
        {
            accessor: 'customerCode',
            Header: t('resource_2:customerCode'),
            width: 200,
            minWidth: 200,
            maxWidth: 220,
            Cell: (rowData: any) => {
                const { customerCode, rowNumber, error, liner } = rowData.row.original;

                // wrapper to manage code manual link
                const Wrapper = ({ children }: any) => {
                    if (codeManualLiners.includes(liner)) {
                        return <InfoDiv title='Click on the icon to find your "Customer Code"'
                            onClick={() => {
                                window.open(`/pdf/CustomerCode/${liner}.pdf`)
                            }}
                        >{children}</InfoDiv>
                    }
                    else return children
                }

                return (
                    <Wrapper>
                        <input
                            type='text'
                            className={`fs--1 form-control ${error.customerCode ? 'is-invalid' : ''}`}
                            disabled={isDisabled}
                            defaultValue={customerCode || ''}
                            onBlur={(e) => {
                                dispatch(setPartnerTableRow(
                                    {
                                        rowNumber,
                                        customerCode: e.target.value.trim()
                                    }
                                ))
                            }}
                        />
                    </Wrapper>
                );

            },
        },
        {
            accessor: 'attachmentName',
            Header: 'Invoice with Customer Code',
            width: 300,
            minWidth: 300,
            maxWidth: 320,
            Cell: (rowData: any) => {
                const { rowNumber, attachmentName, loading, customerCode, error } = rowData.row.original;
                if (isDisabled || disableAttach) return <div className="h-100 d-flex align-items-center"><span
                    className="fs--1"
                    style={{ color: '#2c7be5', cursor: 'pointer' }}
                    onClick={() => {
                        window.open(`/api/user-management/v1/customer/openDocument?id=${requestId}&moduleTp=CUST_MAP&linerId=${linerId}&customerCode=${customerCode}`)
                    }}>{attachmentName}</span></div>
                return (
                    <UploadThing
                        name={attachmentName}
                        loading={loading}
                        labelWidth={'200px'}
                        invalid={error.attachment}
                        fileType={["PDF"]}
                        fileLimit={5}
                        clear={() => {
                            dispatch(setPartnerTableRow(
                                {
                                    rowNumber: rowNumber,
                                    attachmentName: '',
                                    serverPath: null,
                                    serverName: null,
                                    loading: 0
                                }
                            ))
                        }}
                        pending={(loading) => {
                            dispatch(setPartnerTableRow(
                                {
                                    rowNumber: rowNumber,
                                    loading: loading
                                }
                            ))

                        }}
                        fulfilled={(name, serverPath, serverName) => {
                            dispatch(setPartnerTableRow(
                                {
                                    rowNumber: rowNumber,
                                    attachmentName: name,
                                    serverPath: serverPath,
                                    serverName: serverName,
                                    loading: 100
                                }
                            ))
                        }}
                        selected={(name) => {
                            dispatch(setPartnerTableRow(
                                {
                                    rowNumber: rowNumber,
                                    attachmentName: name
                                }
                            ))
                        }}
                        failed={() => {
                            dispatch(setPartnerTableRow(
                                {
                                    rowNumber: rowNumber,
                                    loading: -1
                                }
                            ))
                        }}
                    />
                );
            },
        },

        {
            accessor: 'remove',
            Header: '',
            width: 40,
            minWidth: 40,
            maxWidth: 40,
            Cell: (rowData: any) => {
                const { remove, rowNumber } = rowData.row.original;
                if (!remove) return <></>
                return (
                    <Button
                        variant='falcon-warning'
                        style={{ background: 'transparent', border: 'none', boxShadow: 'none', padding: '0px' }}
                        onClick={() => {
                            const key = crypto.randomUUID()


                            if (!isDisabled && !disableAttach && data.length < 2) {
                                dispatch(getRegistrationPartnerDocConfig({ country: country, liner: linerOptions?.[0]?.value }))
                                    .unwrap().then((data) => {
                                        dispatch(removePartnerTableRow({ rowNumber, key, m: data == 'Y' }))
                                    })
                            } else {
                                dispatch(removePartnerTableRow({ rowNumber, key, m: false }))
                            }

                        }}
                    >
                        <FontAwesomeIcon icon={faTrash} size='sm' />
                    </Button>
                );
            },
            disableSort: true,
        }
    ];

    return <div className='col-lg-12 mt-2 mb-2 demmurageTable d-flex flex-column'>

        {!isDisabled && <Button
            variant='falcon-primary'
            style={{
                width: '160px',
                marginRight: '10px',
                marginBottom: '10px',
            }}
            size='sm'
            onClick={() => {
                const rowNumber = crypto.randomUUID()

                if (!isDisabled && !disableAttach) {
                    dispatch(getRegistrationPartnerDocConfig({ country: country, liner: linerOptions?.[0]?.value }))
                        .unwrap().then((data) => {
                            dispatch(addPartnerTableRow({ key: rowNumber, m: data == 'Y' }))
                        })
                }

            }}>
            <div className='d-flex'>
                <FontAwesomeIcon icon={faPlus} className='me-2 mt-1 ' />
                <span>Customer Code</span>
            </div>
        </Button>}

        <DataTable
            gridName='PARTNER_TABLE'
            columns={tableColumnConfig}
            data={data}
            shortPrepend='reg_pt'
            perPage={100}
            sortable={true}
            searchable={false}
            showColumnFilter={false}
            selection={false}
            pagination={false}
            seekPagination={false}
            showExport={false}
            localFilter={true}
            uniqueFilterKey='partnerTable'
            autoHeight={true}
            maxHeight='16vh'
            tableMinHeight={300}
            staticHiddenCols={isDisabled ? ['rowNumber', "remove"] : ['rowNumber']}
        />
    </div>
}